import React from 'react'

import './footer.css'
export default function Footer() {

    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <b className="subtitle  has-text-weight-bold">ACÉRCATE Y CONOCE MÁS</b>
                <p className="ligth-text">Por la ciencia que nos permite mirar lo humano entre la esfera de lo rural y urbano</p>
                <hr className="separator"/>
                <button className="button contact is-white-ligth is-large ">CONTÁCTANOS</button>
            </div>
            <section className="hero has-text-centered background-blue">
                <div className="hero-body">
                    <p className="subtitle has-text-white">© Azoru 2021</p>
                </div>
            </section>
        </footer>
    )
}