import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

import { 
  HOME, 
  QUIENES_SOMOS, 
  EUROPA,
  CONTACTO,
} from './routes'
import Header from './components/header'
import Footer from './components/footer'
const Home = lazy(() => import('./pages/home'))
const QuienesSomos = lazy(() => import('./pages/quienesSomos'))
const Europa = lazy(() => import('./pages/europa'))
const Contacto = lazy(() => import('./pages/contacto'))

function App() {
  return (
    <>
      <Header />
      <section >
        <div className="container is-fluid px-0">
            <Switch>
              <Route exact path={HOME}>
                <Suspense fallback={<div />}>
                  <Home />
                </Suspense>
              </Route>
              <Route exact path={QUIENES_SOMOS}>
                <Suspense fallback={<div />}>
                  <QuienesSomos />
                </Suspense>
              </Route>
              <Route exact path={EUROPA}>
                <Suspense fallback={<div />}>
                  <Europa />
                </Suspense>
              </Route>
              <Route exact path={CONTACTO}>
                <Suspense fallback={<div />}>
                  <Contacto />
                </Suspense>
              </Route>
            </Switch>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default App;
