// LOGIN
export const HOME = '/'

export const QUIENES_SOMOS = '/quienes-somos'
export const SECTORES = '/sectores-de-intervencion'
export const EUROPA = '/azoru-europa'
export const PROYECTOS_PUBLICOS = '/proyectos-publicos'
export const SERCIVIO_SOCIAL = '/servicio-social-comunitario'
export const TALLERES = '/talleres'
export const ORIENTACION = '/orientacion-y-consejeria'
export const EVENTOS = '/eventos-culturales'
export const ALIANZAS = '/alianzas-estategicas'
export const CONTACTO = '/contacto'