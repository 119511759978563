import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import {
  HOME,
  CONTACTO,
  QUIENES_SOMOS,
  SECTORES,
  SERCIVIO_SOCIAL,
  EUROPA,
  PROYECTOS_PUBLICOS,
  TALLERES,
  ORIENTACION,
  EVENTOS,
} from '../../routes'
// import { ReactComponent as Logo } from '../icons/logo.svg'

import Logo from '../../images/lazoru.png'
import './header.css'

export default function Header() {
  const menu = [
    { name: "Sectores de intervención", link: SECTORES },
    { name: "Proyectos públicos", link: PROYECTOS_PUBLICOS },
    { name: "Servicio social comunitario", link: SERCIVIO_SOCIAL },
    { name: "Talleres", link: TALLERES },
    { name: "Orientación y consejería", link: ORIENTACION },
    { name: "Eventos culturales", link: EVENTOS }
  ]
  const menuEnd = [
    { name: "¿Quiénes somos?", link: QUIENES_SOMOS },
    // { name: "Blog", link: '/blog' },
    { name: "Azoru Europa", link: EUROPA },
    // { name: "Alianzas estatégicas", link: ALIANZAS } 
    { name: "Contacto", link: CONTACTO },
  ]
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)


  function handleMenuMobile() {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }
  return (
    <header className="header">
      <nav
        className={`navbar background-horizontal-gradient ${(isMobileMenuOpen && 'is-fixed-top') || ''
          }`}
      >
        <div className="container is-fluid">
          <div className="navbar-brand">
            <Link to={HOME} className="navbar-item">
              <img src={Logo} width="110" />
            </Link>
            <span
              role="button"
              aria-label="menu"
              aria-expanded="false"
              onClick={handleMenuMobile}
              className={`navbar-burger ${(isMobileMenuOpen && 'is-active') || ''
                }`}
            >
              <span className="has-background-black" aria-hidden="true" />
              <span className="has-background-black" aria-hidden="true" />
              <span className="has-background-black" aria-hidden="true" />
            </span>
          </div>
        </div>
        <div className={`navbar-menu menu-mobile ${(isMobileMenuOpen && 'is-active') || ''}`}>
          <div className="navbar-start is-hidden-desktop">
            <aside className="menu px-4">
              <nav className="navbar">
                <div className="navbar-start">
                  {(menu || []).map(item => {
                    return (<NavLink
                      key={item.name}
                      to={item.link}
                      onClick={handleMenuMobile}
                      className="navbar-item"
                      activeClassName="is-active"
                      isActive={(match, location) => {
                        return location.pathname.includes(
                          !!match && match.url,
                        )
                      }}
                    >
                      {item.name}
                    </NavLink>)
                  })}
                </div>
                <hr className="separator" />
                <div className="navbar-end">
                  {(menuEnd || []).map(item => {
                    return (
                      <NavLink
                        key={item.name}
                        to={item.link}
                        onClick={handleMenuMobile}
                        className="navbar-item is-mega"
                        activeClassName="is-active"
                        isActive={(match, location) => {
                          return location.pathname.includes(
                            !!match && match.url,
                          )
                        }}
                      >
                        {item.name}
                      </NavLink>)
                  })}
                </div>

              </nav>
            </aside>
          </div>
          <div className="navbar-start is-hidden-touch">
            <aside className="menu px-4">
              <nav className="navbar">
                <div className="navbar-end">
                  <div className="navbar-item has-dropdown is-hoverable" >
                    <div className="navbar-link">
                      Servicios
                    </div>
                    <div id="moreDropdown" className="navbar-dropdown">
                      {(menu || []).map(item => {
                        return (
                          <NavLink
                            key={item.name}
                            to={item.link}
                            className="navbar-item is-mega"
                            activeClassName="is-active"
                            isActive={(match, location) => {
                              return location.pathname.includes(
                                !!match && match.url,
                              )
                            }}
                          >
                            {item.name}
                          </NavLink>)
                      })}
                    </div>
                  </div>
                  {(menuEnd || []).map(item => {
                    return (
                      <NavLink
                        key={item.name}
                        to={item.link}
                        className="navbar-item is-mega"
                        activeClassName="is-active"
                        isActive={(match, location) => {
                          return location.pathname.includes(
                            !!match && match.url,
                          )
                        }}
                      >
                        {item.name}
                      </NavLink>)
                  })}
                </div>
              </nav>
            </aside>
          </div>
        </div>
      </nav>
    </header>
  )
}
