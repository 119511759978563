import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import 'bulma/css/bulma.css'
import 'bulma-extensions/dist/css/bulma-extensions.min.css'
import "video-react/dist/video-react.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const browserHistory = createBrowserHistory()

ReactDOM.render(
  <Router history={browserHistory}>
    <App />
  </Router>,
  document.getElementById('root')
)
